<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "MyAccount",

  metaInfo() {
    return {
      title: this.$t("AccountEU.title"),
      titleTemplate: (titleChunk) => {
        return titleChunk
          ? `${titleChunk} - ${this.$t("meta.titleTemplate")}`
          : this.$t("meta.titleTemplate");
      },
    };
  },

  extends: View,

  mixins: [
    LoadSections([
      // 'sections/hero-alt',
      "enduser/sections/enduser-account",
      // 'serviceprovider-account',
      // 'servicelist-account',
      // 'serviceprovidercost-account',
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "enduseraccount",
    },
  },
};
</script>
